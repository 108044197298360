import React from "react"
import Fade from "react-reveal/Fade"
import proy2019 from "../../static/images/proy2019.jpg"
import proy2020 from "../../static/images/proy2020.jpg"
import { Link } from "gatsby"

export default function Projects() {
  return (
    <section id="nosotros" className="pt-5 pb-3">
      <div className="container inner-top inner-bottom-sm">
        <Fade bottom>
          <div className="row">
            <div
              className="col-lg-8 col-md-9 mx-auto text-center aos-init aos-animate pb-4"
              data-aos="fade-up"
            >
              <h1>Últimos proyectos</h1>
            </div>
          </div>
        </Fade>
        <div className="row">
          <Fade left>
            <div className="col-lg-5 col-sm-12 offset-lg-1">
              <div className="item">
                <figure>
                  <div className="icon-overlay icn-link">
                    <a href="portfolio-post.html">
                      <span className="icn-more" />
                      <img src={proy2019} alt="" />
                    </a>
                  </div>
                  <figcaption className="bordered no-top-border">
                    <div className="info">
                      <h4>Ciudad de México</h4>
                      <p>
                        <small>
                          Proyecto de instalaciones de Telecomunicaciones y
                          Seguridad Policlínica nuestra señora del Carmen CDMX.
                        </small>
                      </p>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="col-lg-5 col-sm-12">
              <div className="item">
                <figure>
                  <div className="icon-overlay icn-link">
                    <a href="portfolio-post.html">
                      <span className="icn-more" />
                      <img src={proy2020} alt="" />
                    </a>
                  </div>
                  <figcaption className="bordered no-top-border">
                    <div className="info">
                      <h4>Ciudad de México</h4>
                      <p>
                        <small>
                          Proyecto de instalaciones de Telecomunicaciones y
                          Seguridad para el Nuevo hospital de especialidades
                          médicas Facultad de Medicina Veterinaria y Zootécnica
                          de la UNAM
                        </small>
                      </p>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </Fade>
          <div className="col-lg-12 text-center">
            <Link to="/proyectos" className="btn btn-large">
              VER TODOS LOS PROYECTOS
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
