import React from "react"
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Slide"
import { Link as ScrollLink } from "react-scroll"
import teamPic from "../../static/images/team.jpg"
import icono1 from "../../static/images/icono1.png"
import icono2 from "../../static/images/icono2.png"
import icono3 from "../../static/images/icono3.png"
import icono4 from "../../static/images/icono4.png"

export default function Team() {
  return (
    <section id="latest-works" className="light-bg pt-4 pb-3">
      <div className="container inner">
        <div className="row">
          <Fade bottom>
            <div
              className="col-lg-8 col-md-9 mx-auto text-center p-4"
              data-aos="fade-up"
            >
              <h1>Nuestro equipo</h1>
              <p>
                Contamos con un equipo de profesionales comprometidos con cada
                uno de los proyectos encomendados por nuestros clientes.
              </p>
            </div>
          </Fade>
        </div>
        <div className="row inner-top-md pb-4">
          <Fade left>
            <div
              className="col-md-6 order-md-1 inner-left-xs"
              data-aos="fade-up"
            >
              <ul className="list-unstyled">
                <li>
                  Mauricio Hermosillo Hernández - <small>Director</small>
                </li>
              </ul>
              Conócenos y sabrás quienes son nuestros colaboradores. Esperamos
              tu llamada.
              <div className="fadeInLeft-3">
                <ScrollLink
                  className="btn btn-large"
                  to="contacto"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={2500}
                >
                  CONTACTO
                </ScrollLink>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div
              className="col-md-6 order-md-2 inner-top-xs inner-right-xs"
              data-aos="fade-up"
            >
              <div className="card">
                <img className="card-img-top" src={teamPic} alt={teamPic} />
                <div className="card-body">
                  <h5 className="card-title text-center">
                    No esperes más y platica con nosotros
                  </h5>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div className="row">
          <Fade top>
            <div
              className="col-lg-8 col-md-9 mx-auto text-center p-4"
              data-aos="fade-up"
            >
              <h1>Más de 10 años de experiencia</h1>
            </div>
          </Fade>
        </div>
        <Slide right cascade>
          <div className="row inner-top-sm text-center">
            <div className="col-md-3 inner-bottom-xs" data-aos="fade-up">
              <div className="icon">
                <img src={icono1} className="icon-team" alt="tecnicos" />
              </div>
              <h5>
                Conocimientos
                <br /> técnicos
              </h5>
            </div>

            <div className="col-md-3 inner-bottom-xs pb-5" data-aos="fade-up">
              <div className="icon">
                <img src={icono2} className="icon-team" alt="gestion" />
              </div>
              <h5>
                Conocimientos
                <br /> de gestión
              </h5>
            </div>

            <div className="col-md-3 inner-bottom-xs" data-aos="fade-up">
              <div className="icon">
                <img src={icono3} className="icon-team" alt="resolucion" />
              </div>
              <h5>
                Resolución de <br /> problemas
              </h5>
            </div>

            <div className="col-md-3 inner-bottom-xs" data-aos="fade-up">
              <div className="icon">
                <img src={icono4} className="icon-team" alt="personales" />
              </div>
              <h5>
                Relaciones <br />
                personales
              </h5>
            </div>
          </div>
        </Slide>
      </div>
    </section>
  )
}
