import React, { useState } from "react"
import axios from "axios"
import Fade from "react-reveal/Fade"

export default function Contact() {
  const [form, setValues] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [errContact, setErrContact] = useState(false)
  const [sent, setSent] = useState(false)

  const handleChange = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    console.log(form)
    axios({
      method: "post",
      url: "/contactscript.php",
      headers: { "content-type": "application/json" },
      data: form,
    })
      .then(respuesta => {
        if (respuesta.data.sent) {
          setSent(true)
          setTimeout(() => {
            setSent(false)
          }, 2000)
          setValues({
            name: "",
            email: "",
            message: "",
          })
        } else {
          setErrContact(true)
          setTimeout(() => {
            setErrContact(false)
          }, 2000)
        }
      })
      .catch(err => {
        setErrContact(true)
        setTimeout(() => {
          setErrContact(false)
        }, 2000)
      })
  }
  return (
    <section
      id="contacto"
      className="tint-bg dark-bg img-bg-soft img-bg bg-contact pt-5 pb-5"
    >
      <div className="container">
        <Fade bottom>
          <div className="row">
            <div
              className="col-lg-8 col-md-9 inner mx-auto text-center aos-init aos-animate pb-4"
              data-aos="fade-up"
            >
              <header>
                <h1>Queremos trabajar contigo</h1>
                <p>¿Tienes alguna duda? Estamos para atenderte</p>
              </header>
            </div>
          </div>
        </Fade>
        <div className="row">
          <div className="col-lg-5 offset-lg-1 col-sm-12">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input
                  value={form.name}
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder=""
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Correo</label>
                <input
                  value={form.email}
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder=""
                  onChange={handleChange}
                />
              </div>
              <div className="from-group">
                <label htmlFor="message">Mensaje</label>
                <textarea
                  value={form.message}
                  name="message"
                  className="form-control"
                  placeholder=""
                  onChange={handleChange}
                />
              </div>
              {sent && (
                <span className="text-success">Gracias por contactarnos</span>
              )}
              {errContact && (
                <span className="text-danger">
                  Ocurrió un error al enviar el mensaje
                </span>
              )}
              <button type="submit" className="btn btn-submit float-right">
                Enviar
              </button>
            </form>
          </div>
          <div className="col-lg-4 col-sm-12">
            <ul className="list-unstyled pt-5">
              <li className="text-light">
                <div className="d-flex">
                  <span>Teléfonos: </span>
                  <span className="pl-2"> 55 2155 4518</span>
                </div>
              </li>
              <li className="text-light">
                <a
                  href="mailto:contacto@mcproyectostelecom.com"
                  style={{ color: "#58c1e5" }}
                >
                  contacto@mcproyectostelecom.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
