import React from "react"
import Fade from "react-reveal/Fade"
import ClientSlider from "./clientSlider"

export default function Clients({ clients, marcas }) {
  return (
    <section id="clientes" className="pt-5 pb-3 client-bg">
      <div className="container inner-top inner-bottom-sm">
        <Fade bottom>
          <div className="row">
            <div
              className="col-lg-8 col-md-9 mx-auto text-center aos-init aos-animate pb-4"
              data-aos="fade-up"
            >
              <h1>Clientes</h1>
              <p>
                Agradecemos la confianza que nuestros clientes nos han brindado
                durante toda nuestra trayectoria, confianza que ha permitido
                consolidarnos dentro del mercado de diseño de instalaciones.
              </p>
            </div>
          </div>
        </Fade>
        <div className="row">
          <div className="col-lg-12 pb-4">
            <ClientSlider imagenes={clients} />
          </div>
        </div>
        <Fade bottom>
          <div className="row">
            <div
              className="col-lg-8 col-md-9 mx-auto text-center aos-init aos-animate pt-4 pb-4"
              data-aos="fade-up"
            >
              <h1>Marcas</h1>
              <p>
                Los logos de las diversas marcas son exclusivamente para
                informar que se pueden proponer durante el desarrollo de algún
                proyecto, el exponerlos en esta página no tiene ningún fin
                comercial ni de lucro.
              </p>
            </div>
          </div>
        </Fade>
        <div className="row">
          <div className="col-lg-12 pb-4">
            <ClientSlider imagenes={marcas} />
          </div>
        </div>
      </div>
    </section>
  )
}
