import React from "react"
import Fade from "react-reveal/Fade"
import Bounce from "react-reveal/Bounce"
import LightSpeed from "react-reveal/LightSpeed"
import vozdatos from "../../static/images/vozdatos.png"
import circuito from "../../static/images/circuito.png"
import audio from "../../static/images/audio.png"
import accesos from "../../static/images/accesos.png"
import incendios from "../../static/images/incendios.png"
import tv from "../../static/images/tv.png"
import control from "../../static/images/control.png"
import telemedicina from "../../static/images/telemedicina.png"
import paciente from "../../static/images/paciente.png"
import monitoreo from "../../static/images/monitoreo.png"

export default function Process() {
  return (
    <section className="tint-bg img-bg-soft img-bg img-bg-process pt-5 pb-5">
      <div className="container">
        <Fade bottom>
          <div className="row" id="servicios">
            <div
              className="col-lg-8 col-md-9 inner mx-auto text-center aos-init aos-animate pb-4"
              data-aos="fade-up"
            >
              <header>
                <h1>Servicios</h1>
                <p>
                  Nos especializamos en el diseño de proyectos para
                  instalaciones de:
                </p>
              </header>
            </div>
          </div>
        </Fade>
        <Bounce top cascade>
          <div className="row inner-top-sm pb-3 text-center">
            <div
              className="col-lg-2 offset-lg-1 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img src={vozdatos} className="icon-team" alt="tecnicos" />
              </div>
              <h5 className="text-light">
                Telefonía e
                <br /> Informática
              </h5>
            </div>
            <div
              className="col-lg-2 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img src={circuito} className="icon-team" alt="gestion" />
              </div>
              <h5 className="text-light">
                Circuito Cerrado
                <br /> de Televisión
              </h5>
            </div>
            <div
              className="col-lg-2 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img src={audio} className="icon-team" alt="resolucion" />
              </div>
              <h5 className="text-light">Audio</h5>
            </div>
            <div
              className="col-lg-2 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img src={accesos} className="icon-team" alt="personales" />
              </div>
              <h5 className="text-light">
                Control de <br />
                Acessos
              </h5>
            </div>
            <div
              className="col-lg-2 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img src={incendios} className="icon-team" alt="personales" />
              </div>
              <h5 className="text-light">
                Detección de <br />
                incendios
              </h5>
            </div>
          </div>
        </Bounce>
        <Bounce top cascade>
          <div className="row inner-top-sm pb-3 text-center">
            <div
              className="col-lg-2 offset-lg-1 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img src={tv} className="icon-team" alt="tecnicos" />
              </div>
              <h5 className="text-light">
                Televisión Fomento
                <br /> a la Salud
              </h5>
            </div>
            <div
              className="col-lg-2 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img src={control} className="icon-team" alt="gestion" />
              </div>
              <h5 className="text-light">
                Control y
                <br /> Monitoreo
              </h5>
            </div>
            <div
              className="col-lg-2 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img
                  src={telemedicina}
                  className="icon-team"
                  alt="resolucion"
                />
              </div>
              <h5 className="text-light">Telemedicina</h5>
            </div>
            <div
              className="col-lg-2 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img src={paciente} className="icon-team" alt="personales" />
              </div>
              <h5 className="text-light">
                Paciente <br />
                Enfermera
              </h5>
            </div>
            <div
              className="col-lg-2 inner-bottom-xs aos-init aos-animate pb-3"
              data-aos="fade-up"
            >
              <div className="icon">
                <img src={monitoreo} className="icon-team" alt="personales" />
              </div>
              <h5 className="text-light">
                Monitores de <br />
                Signos Vitales
              </h5>
            </div>
          </div>
        </Bounce>
        <div className="row" id="process">
          <Fade bottom>
            <div
              className="col-lg-8 col-md-9 inner mx-auto text-center aos-init aos-animate pb-4"
              data-aos="fade-up"
            >
              <header>
                <h1>Nuestro Proceso</h1>
                <p>
                  Todos los proyectos se desarrollan de manera integral tomando
                  como base el diseño arquitectónico del inmueble, a través de
                  los siguientes elementos:
                </p>
              </header>
            </div>
          </Fade>
        </div>
        <div className="row inner-top-sm text-center steps-process">
          <LightSpeed top>
            <div
              className="col-md-3 inner-bottom-xs aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="icon">
                <span className="step">01</span>
              </div>
              <h4>
                Diseño
                <br /> <small>de planos de infraestructura</small>
              </h4>
            </div>
          </LightSpeed>
          <LightSpeed top>
            <div
              className="col-md-3 inner-bottom-xs aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="icon">
                <span className="step">02</span>
              </div>
              <h4>
                Cuantificación
                <br /> <small>de Materiales</small>
              </h4>
            </div>
          </LightSpeed>
          <LightSpeed top>
            <div
              className="col-md-3 inner-bottom-xs aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="icon">
                <span className="step">03</span>
              </div>
              <h4>
                Catálogo
                <br /> <small>de Conceptos</small>
              </h4>
            </div>
          </LightSpeed>
          <LightSpeed top>
            <div
              className="col-md-3 inner-bottom-xs aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="icon">
                <span className="step">04</span>
              </div>
              <h4>
                Documentación
                <br /> <small>Técnica Descriptiva</small>
              </h4>
            </div>
          </LightSpeed>
        </div>
      </div>
    </section>
  )
}
