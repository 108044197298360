import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import About from "../components/about"
import Team from "../components/team"
import Process from "../components/process"
import Clients from "../components/clients"
import Projects from "../components/projects"
import Contact from "../components/contact"
import Map from "../components/map"

export const query = graphql`
  query GET_CLIENTS {
    clients: allFile(filter: { relativeDirectory: { eq: "clients" } }) {
      edges {
        node {
          id
          publicURL
          size
          name
          absolutePath
          dir
        }
      }
    }
    marcas: allFile(filter: { relativeDirectory: { eq: "marcas" } }) {
      edges {
        node {
          id
          publicURL
          size
          name
          absolutePath
          dir
        }
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout noNav={false}>
    <SEO title="MC Proyectos Telecom | Proyectos de Telecomunicaciones" />

    <Header />

    <About />

    <Team />

    <Process />

    <Clients clients={data.clients.edges} marcas={data.marcas.edges} />

    <Projects />

    <Contact />

    <Map />
  </Layout>
)

export default IndexPage
