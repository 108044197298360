import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function SamplePrevArrow(props) {
  const { onClick } = props
  return <div className="nav-btn prev-slide" onClick={onClick} />
}

function SampleNextArrow(props) {
  const { onClick } = props
  return <div className="nav-btn next-slide" onClick={onClick} />
}

export default function ClientSlider({ imagenes }) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <div>
      <Slider {...settings}>
        {imagenes.map(({ node }) => {
          return (
            <div className="card card-slider" key={node.id}>
              <img
                className="card-img-top"
                src={node.publicURL}
                alt={node.name}
              />
              {/* <div className="card-body">
                <h5 className="card-title text-center">{node.name}</h5>
              </div> */}
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
