import React from "react"

export default function Map() {
  return (
    <section className="mapa">
      <iframe
        title="mapa"
        frameBorder="0"
        height="750"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.7511058258815!2d-99.17233168509546!3d19.293186986963796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce0061cba2931d%3A0xa3db4e1843cf4fc5!2sAvenida%20San%20Fernando%2C%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1568863962429!5m2!1ses-419!2smx"
        style={{ border: 0 }}
        width="100%"
      />
    </section>
  )
}
