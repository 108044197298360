import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <div className="nav-btn-slider" onClick={onClick}>
      <i className="icon-left-open-mini" />
    </div>
  )
}

function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <div className="nav-btn-slider right" onClick={onClick}>
      <i className="icon-right-open-mini" />
    </div>
  )
}

export default function Header() {
  const settings = {
    focusOnSelect: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 2200,
    fade: false,
    cssEase: "linear",
    mobileFirst: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: dots => (
      <div className="dots-control">
        <ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
      </div>
    ),
  }

  return (
    <header id="inicio">
      <Slider {...settings}>
        <div className="masthead bg-1">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-12 text-center">
                <h1 className="font-weight-black">
                  Soluciones <br /> Profesionales
                </h1>
                <p className="lead-header">
                  Instalaciones de Telecomunicaciones y Seguridad
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="masthead bg-2">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-12 text-center">
                <h1 className="font-weight-black">
                  Proyectos de
                  <br /> Vanguardia
                </h1>
                <Link to="/proyectos" className="btn btn-large">
                  Diseño de Proyectos para instalaciones
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="masthead bg-3">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-12 text-center">
                <h1 className="font-weight-black">Casos de Éxito</h1>
                <Link to="/casos_exito" className="btn btn-large">
                  Ver los casos
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </header>
  )
}
