import React from "react"
import Fade from "react-reveal/Fade"

export default function About() {
  return (
    <section id="nosotros" className="mt-5 mb-3">
      <div className="container inner-top inner-bottom-sm">
        <Fade bottom>
          <div className="row">
            <div
              className="col-lg-8 col-md-9 mx-auto text-center aos-init aos-animate pb-4"
              data-aos="fade-up"
            >
              <h1>Sobre nosotros</h1>
              <p>
                Nuestro principal objetivo consiste en ofrecer a nuestros
                clientes soluciones profesionales e integrales de acuerdo a sus
                necesidades.
              </p>
            </div>
          </div>
        </Fade>
        <div className="row inner-top-sm about-us">
          <Fade left>
            <div
              className="col-lg-1 col-sm-2 col-3 text-right aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="icon pos-left">
                <i className="icon-heart-empty-1 icn" />
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-10 col-9 inner-bottom-xs aos-init aos-animate"
              data-aos="fade-up"
            >
              <h2>Misión</h2>
              <p className="text-small">
                Satisfacer en su totalidad el requerimiento de diseño de
                instalaciones de comunicaciones y seguridad de cada uno de
                nuestros clientes. Ya sea iniciativa privada o sector público.
              </p>
            </div>
          </Fade>
          <Fade left>
            <div
              className="col-lg-1 col-sm-2 col-3 text-right aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="icon pos-left">
                <i className="icon-lamp icn" />
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-10 col-9 inner-bottom-xs aos-init aos-animate"
              data-aos="fade-up"
            >
              <h2>Visión</h2>
              <p className="text-small">
                Incrementar nuestra participación en el mercado de diseño de
                proyectos ejecutivos de instalaciones, a través de la
                satisfacción total de nuestros clientes derivado de la calidad
                en los proyectos y servicios recibidos.
              </p>
            </div>
          </Fade>
          <Fade left>
            <div
              className="col-lg-1 col-sm-2 col-3 text-right aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="icon pos-left">
                <i className="icon-star-empty-1 icn" />
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-10 col-9 inner-bottom-xs aos-init aos-animate"
              data-aos="fade-up"
            >
              <h2>Valores</h2>
              <p className="text-small">- Compromiso</p>
              <p className="text-small">- Confianza</p>
              <p className="text-small">- Innovación</p>
              <p className="text-small">- Eficiencia</p>
            </div>
          </Fade>
        </div>

        <div className="row">
          <Fade>
            <div className="col-lg-10 offset-lg-1">
              <p className="text-small text-justify pt-3">
                Somos un equipo de profesionales que apostamos hace más de 10
                años en la conformación de un despacho de diseño de
                instalaciones de comunicaciones y seguridad. Hemos participado y
                colaborado en el diseño de diversos proyectos ejecutivos de
                instalaciones, tanto para el sector público como el privado,
                considerando siempre el mantenernos actualizados respecto a los
                cambios tecnológicos que van sucediendo en nuestro entorno.
                Hemos contado en nuestras filas con la participación de gente
                muy valiosa, quienes han visto en la participación con nosotros
                el beneficio de poder adquirir experiencia que les sirva en su
                desempeño profesional futuro. Hoy día hemos visto nuestro
                proyecto consolidado contando con un despacho de diseño de
                instalaciones, y conforme avanza el tiempo hemos mejorado
                nuestros procesos, hemos ampliado nuestra cartera de clientes y
                nos hemos posicionado en este inmenso mercado de las
                comunicaciones en nuestro país. Gracias a todos nuestros
                clientes por su confianza, a nuestros colaboradores, a nuestros
                colegas y amigos. Y a quienes han depositado la confianza en
                este proyecto.{" "}
              </p>
              <p className="text-small text-justify pt-2">
                La Ingeniería siempre de la mano con la Arquitectura podrá crear
                mejores ambientes para nuestros usuarios finales.
              </p>
              <p className="text-right text-small">
                <span className="italic"> Mauricio Hermosillo Hernández,</span>{" "}
                Director.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}
